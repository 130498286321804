<template>
	<div id="BoxItem" :class="item.status | GuoLv" @click="ToVsBox()">
		<div class="box_ceng" v-show="item.status == '2'"></div>
		<div class="Left">
			<div class="top">
				<p :style="item.status_alias | STYSC"><img :src="item.status_alias | STYImg" alt="">{{ item.status_alias }}
				</p><span>{{ item.box_num }} 回合</span>
			</div>
			<div class="D_box">
				<div class="PeadPortrait" v-for="(item, index) in ItemArr" :key="Num[index]">
					<img v-if="item == undefined" src="@/assets/images/PublicImg/Kong.png" alt="">
					<img v-else :src="item.avatar | ImgRoute" alt="">
				</div>
			</div>

			<div class="Price">
				<p><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{ item.total_bean }}</p>
			</div>


			<div class="txt_wm">
				<p>{{ item.status | GuoLvTwo }}</p>
			</div>
		</div>
		<div class="Right">
			<div class="case" v-for="(item, index) in item.game_arena_box" :key='index'>
				<div class="pic"><img :src="item.cover | ImgRoute" alt=""></div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapState
} from 'vuex'
export default {
	name: 'BoxItem',
	props: {
		item: {
			type: Object,
		},
		VsStart: {
			type: Object
		}
	},
	data() {
		return {
			Num: [
				0, 1, 2, 3
			],
			ItemArr: []
		}
	},


	filters: {
		ImgRoute(str) {
			return localStorage.getItem('static_file_url') + str
		},
		GuoLv(val) {
			if (val == 0) {
				return 'DengDai'
			} else if (val == 1) {
				return 'JinXing'
			} else if (val == 2) {
				return ' '
			}

		},

		GuoLvTwo(val) {
			if (val == 0) {
				return '挑战'
			} else if (val == 1) {
				return '观战'
			} else {
				return '查看'
			}
		},
		STYSC(str) {
			if (str == '等待中') {
				return 'color: #02BF4D'
			} else if (str == '进行中') {
				return 'color: #ea4391'
			} else if (str == '已结束') {
				return 'color: #9d9d9d'
			}
		},
		STYImg(str) {
			if (str == '等待中') {
				return require('@/assets/images/Vs/DengDai.png')
			} else if (str == '进行中') {
				return require('@/assets/images/Vs/Jinxing.png')
			} else if (str == '已结束') {
				return require('@/assets/images/Vs/Jieshu.png')
			}
		}
	},

	created() {
		let Arr = []
		for (let i = 0; i < Number(this.item.user_num); i++) {
			Arr[i] = undefined
		}
		for (let k = 0; k < this.item.game_arena_player.length; k++) {
			Arr[Number(this.item.game_arena_player[k].seat)] = this.item.game_arena_player[k]
		}
		this.ItemArr = Arr
	},

	methods: {
		Refresh() {
			this.Num = this.Num.map((item) => {
				return item + 1
			})
		},

		//对战
		ToVsBox() {
			this.seeShare()

		},
		seeShare() {
			let routeUrl = this.$router.resolve({
				path: "/vsbox",
				query: {
					id: this.item.id,
					Code: this.item.status
				}
			});
			window.open(routeUrl.href, '_self');
		}

	},

	computed: {
		...mapState([
			'AddInformation',
			'VsKaiShi',
			'VsJieShu',
			'KaiShiVsData',
			'JieShuVsData'
		])
	},

	watch: {
		AddInformation(val) {
			if (val.game_arena_id == this.item.id) {
				this.ItemArr[Number(val.seat)] = val
				this.Refresh()
			}
		},

		VsKaiShi(val) {
			if (val.game_arena_id == this.item.id) {
				this.item.status = val.status
				this.item.status_alias = val.status_alias
			}
		},
		VsJieShu(val) {
			if (val.id == this.item.id) {
				this.item.status = val.status
				this.item.status_alias = val.status_alias
			}
		},

		KaiShiVsData(val) {
			if (val.game_arena_id == this.item.id) {
				this.item.status = val.status
				this.item.status_alias = val.status_alias
			}
		},
		JieShuVsData(val) {
			if (val.id == this.item.id) {
				let i = 11 * Number(this.item.box_num)
				let k = Number(String(i) + '000')
				setTimeout(() => {
					this.item.status = val.status
					this.item.status_alias = val.status_alias
				}, k)
			}
		}
	}
}
</script>

<style lang="scss">
#BoxItem {
	width: 3.4rem;
	// background: url(../../../assets/images/public/back_pub.jpg);
	background-image: url(../../../assets/images/Vs/JS.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	border-radius: .1rem;
	overflow: hidden;
	cursor: pointer;
	margin-bottom: 0.26rem;
	margin-right: .26rem;
	padding: .1rem;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	position: relative;

	&:nth-child(3n) {
		margin-right: 0;
	}

	&.DengDai {
		background-image: url(../../../assets/images/Vs/DD.png);
	}

	&.JinXing {
		background-image: url(../../../assets/images/Vs/JX.png);
	}

	.box_ceng {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba($color: #000, $alpha: .3);
	}

	.Left {
		width: 2.6rem;
		padding-right: .2rem;
		box-sizing: border-box;

		.top {
			width: 100%;
			height: .4rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				font-size: 14px;
				font-weight: bold;

				img {
					width: 18px;
					vertical-align: middle;
					margin-bottom: 3px;
					margin-right: 3px;
				}
			}

			span {
				font-size: 16px;
				color: #fff;
			}
		}

		.D_box {

			width: 1.9rem;
			height: 1.4rem;
			margin: .1rem auto;
			border-radius: 100vh;
			padding: .1rem;
			box-shadow: inset 0 0 4px 4px rgba(30, 204, 207, 0.4);

			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap-reverse;

			.PeadPortrait {
				width: 0.6rem;
				height: 0.6rem;
				border-radius: 50%;
				margin: 0.02rem 0.04rem;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.Price {
			width: 100%;
			height: .3rem;

			p {
				width: 100%;
				text-align: center;
				line-height: .3rem;
				font-size: .22rem;
				color: #ffd322;

				img {
					vertical-align: middle;
					margin-bottom: 4px;
					margin-right: 2px;
					width: 20px;
				}
			}
		}

		.txt_wm {
			width: 100%;
			height: .4rem;
			margin-top: .1rem;

			p {
				width: 1rem;
				height: .3rem;
				margin: 0 auto;
				background: rgba(255, 255, 255, 0.1);
				border: 2px solid #fff;
				border-radius: .2rem;
				text-align: center;
				line-height: .3rem;
				font-size: .18rem;
			}
		}
	}

	.Right {
		width: .5rem;
		height: 100%;
		border-left: 1px solid rgba($color: #fff, $alpha: .6);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		.case {
			width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

}
</style>
