<template>
  <div class="view-member">
    <!-- <UserHeader :propType="4">{{ headerName }}</UserHeader> -->
    <div v-if="current == 0" class="tab-box">
      <div
        v-for="(item, index) in headerTabList"
        @click="$router.push(item.href)"
        :key="index"
        class="tab-item"
        :class="{ 'tab-item-active': index == 1 }">
        <img
          style="width: 0.2rem; height: 0.2rem; margin-right: 0.04rem"
          :src="require(`@/assets/images/new/${item.icon}`)"
          alt=""
          srcset="" />
        {{ item.title }}
      </div>
    </div>
    <div v-if="current == 0" class="box-mod">
      <div class="VSbox_list">
        <div class="scroll-wrap">
          <div
            class="wrap001"
            ref="scrollDiv"
            @mousewheel.prevent="MouseWheelOnScroll($event)"
            :style="{ transform: `translateY(${boxListScroll * 0.605}rem)` }">
            <div
              class="item-box"
              @click="OpenXZshow(item)"
              v-for="(item, index) in hotboxlist"
              :key="index">
              <div class="vsbox_pic">
                <div class="img">
                  <img class="img1" :src="item.intact_cover" alt="" />
                  <img class="img2" :src="item.weapon_cover" alt="" />
                </div>
              </div>
              <div class="vsbox_data">
                <span>{{ item.name }}</span>
                <div class="p">
                  <Cprice :size="0.08" :price="item.game_bean" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="current == 0" class="Navigation1">
      <div @click="showTopBox = !showTopBox" class="Navigation-left">
        <div class="right-text">排行榜</div>
      </div>
      <div
        @click="changeCurrentOnClick(1)"
        class="Navigation-left Navigation-left2">
        <div class="right-text">游戏历史</div>
      </div>
      <div @click="Getdrawer" class="Navigation-right">
        <div class="filter-box"></div>
        <div class="bg-box"></div>
        <img
          class="img"
          src="@/assets/images/battle/icon2-new.png"
          alt="" />创建房间
      </div>
    </div>
    <div v-if="current == 1" class="header-box2">
      <div class="back-box2" @click="changeCurrentOnClick(0)"></div>
      <div>游戏历史</div>
    </div>
    <div v-if="current == 1" class="header-box3">
      <div
        @click="dropItemClick(item)"
        v-for="(item, index) in ['全部', '我的']"
        :key="index"
        class="item"
        :class="{ 'active-item': activeName == item }">
        {{ item }}
      </div>
    </div>
    <div class="content-list">
      <div class="fight-box-list">
        <RoomItem
          class="fight-box-list-item"
          v-for="(item, index) in Ar_List"
          :key="index"
          :item="item"></RoomItem>
      </div>
    </div>
    <div v-if="current != 2" class="common-pagination2">
      <Pagination
        :total="PageNum"
        :page.sync="page_num"
        :limit.sync="per_page"
        @pagination="handleCurrentChange" />
      <!-- <el-pagination background layout="total, pager" :pager-count="5" @current-change="handleCurrentChange" :page-size="per_page" :total="PageNum">
      </el-pagination> -->
    </div>
    <van-overlay :lock-scroll="false" :show="show" @click="show = false">
      <div class="battle-show-wrapper1" @click.stop>
        <Transition name="popup">
          <div class="popup-show-member-2" v-if="show">
            <div class="popup-show-member-box">
              <div class="box-main-right">
                <div class="right-center-box-list">
                  <div
                    @click="chooceOnclick(item, index)"
                    v-for="(item, index) in hotboxlist"
                    :key="index"
                    class="box-list-item point"
                    :class="{ 'box-list-item1': chooceListNum[item.id + ''] }">
                    <div class="left-box">
                      <img class="img" :src="item.intact_cover" alt="" />
                      <div
                        v-if="chooceListNum[item.id + ''] > 0"
                        class="num-box">
                        {{ chooceListNum[item.id + ""] }}
                      </div>
                      <div
                        v-if="chooceListNum[item.id + '']"
                        @click.stop="chooceOnclickDown(item, index)"
                        class="down"></div>
                    </div>
                    <div class="right-content">
                      <div class="content-title">{{ item.name }}</div>
                      <div class="content-price">
                        <Cprice :size="0.1" :price="item.game_bean" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btm-box">
                  <div class="btm-left">
                    <div
                      @click="leftFunOnclick(item)"
                      v-show="
                        index === 0 || (leftFunOne === BAR3 && index === 1)
                      "
                      v-for="(item, index) in [BAR1, BAR2]"
                      class="btm-left1 flex point"
                      :key="item">
                      <div
                        class="left1-checkbox"
                        :class="{
                          'left1-checkbox-checked': leftFun === item,
                        }"></div>
                      <div class="left2-checkbox">{{ item }}</div>
                    </div>
                  </div>
                  <div class="btm-center flex">
                    <div v-if="leftFun === BAR1" class="join-list flex">
                      <div
                        @click="leftFunOnclickOne(item)"
                        v-for="item in [BAR3, BAR4]"
                        :key="item"
                        class="join-list-item point"
                        :class="{
                          'join-list-item-checked': leftFunOne === item,
                        }">
                        {{ item }}
                      </div>
                    </div>
                    <div v-if="leftFun === BAR2" class="rob-list">
                      <div
                        @click="chooseRobOnClick(item)"
                        class="rob-list-item point"
                        :class="{
                          'rob-list-item-active': chooseRob?.id === item?.id,
                        }"
                        v-for="item in robList"
                        :key="item.id">
                        <div class="rob-img">
                          <img :src="item.avatar" alt="" />
                        </div>
                        <div class="rob-name">
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="btm-right">
                    <div class="all-num">{{ chooceIdList.length }}/10</div>
                    <div @click="chooceIdList = []" class="clear-box">
                      <img
                        style="
                          width: 0.13rem;
                          height: 0.12rem;
                          margin-right: 0.04rem;
                        "
                        src="@/assets/images/battle/clear-bg.png"
                        alt=""
                        srcset="" />
                      全部清除
                    </div>
                    <div @click="createRoomOnClick1" class="right-btn point">
                      <img
                        style="width: 0.12rem; margin-right: 0.05rem"
                        src="@/assets/images/about/user/huobi.png"
                        alt="" /><span
                        style="color: #efa900; margin-right: 0.06rem"
                        >{{ TotalPrice }}</span
                      >
                      创建对局
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div @click="show = false" class="close-btn-left point"></div>
          </div>
        </Transition>
      </div>
    </van-overlay>
    <van-overlay class="sort-box-wrap" :lock-scroll="false" :show="showTopBox">
      <div v-if="showTopBox" class="sort-box">
        <div class="close-btn" @click="showTopBox = false"></div>
        <div class="battle-search-box">
          <div
            class="search-item"
            :class="searchCurrent == index ? 's-ac' : ''"
            v-for="(item, index) in searchList"
            :key="index"
            @click="searchChange(index, item.type)">
            {{ item.name }}
          </div>
        </div>
        <div class="sort-l">
          <div class="l-box">
            <div class="game-list">
              <div class="item-two">
                <div class="pic">
                  <img :src="No2.user.avatar" alt="" />
                </div>
                <p class="name elips1">{{ No2.user.name }}</p>
                <p class="integral elips1">
                  <Cprice :size="0.12" :price="No2.win" />
                </p>
              </div>
              <div class="item-one">
                <div class="pic">
                  <img :src="No1.user.avatar" alt="" />
                </div>
                <p class="name elips1">{{ No1.user.name }}</p>
                <p class="integral elips1">
                  <Cprice :size="0.12" :price="No1.win" />
                </p>
              </div>
              <div class="item-three">
                <div class="pic">
                  <img :src="No3.user.avatar" alt="" />
                </div>
                <p class="name elips1">{{ No3.user.name }}</p>
                <p class="integral elips1">
                  <Cprice :size="0.12" :price="No3.win" />
                </p>
              </div>
            </div>
            <!-- <img src="@/assets/images/battle/sort-bt.png" class="sort-bt" alt="" /> -->
          </div>
        </div>
        <div class="sort-r">
          <div class="sort-list">
            <div
              class="sort-item"
              v-for="(item, index) in renderList.slice(3, 10)"
              :key="index">
              <div class="num">
                {{ index + 4 }}
              </div>
              <div class="header-img">
                <img :src="item.user.avatar" alt="" class="img" />
              </div>
              <div class="name">
                {{ item.user.name }}
              </div>
              <div class="price">
                <Cprice :size="0.12" :price="restIntegral(item.win)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <OverlayBoxList
      @close="XZshow = false"
      :game_odds_list="game_odds_list"
      :XZshow="XZshow"
      :boxList="Treasure_box_prize" />
    <Tabbar />
  </div>
</template>

<script>
import { PersonalInfo } from "@/network/api.js";
import RoomItem from "./roomItem";
import pagination from "@/components/pagination/index.vue";
import drop from "@/components/drop/index.vue";
import OverlayBoxList from "./OverlayBoxList.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import dayjs from "dayjs";
import _ from "lodash";
import {
  searchList,
  // sortUserList,
  imgSort,
  tabStatusList,
  list,
  popupTabList,
} from "../index.js";
import {
  Arena_List,
  My_Arena_List,
  Ten_Arena_List,
  Arena_Box_List,
  Arena_Create,
  getRanking,
  winRanking,
  getRobListApi,
  joinRobApi,
} from "@/network/api.js";
import UserHeader from "@/components/common/UserHeader.vue";
export default {
  components: {
    RoomItem,
    pagination,
    drop,
    UserHeader,
    OverlayBoxList,
  },
  props: {
    current: 0,
  },
  data() {
    return {
      showTopBox: false,
      BAR1: "玩家对战",
      BAR2: "人机对战",
      BAR3: "两人",
      BAR4: "三人",
      popupTabList,
      page_num: 1,
      list,
      PeopleNum: 2,
      tabStatusList,
      imgSort,
      sortUserList: [],
      searchList,
      per_page: 0,
      PageNum: 0,
      boxListScroll: 0,
      XZshow: false,
      Treasure_box_prize: [], //宝箱奖品数据
      TreasureName: "",
      hotboxlist: [],
      chooceList: [],
      chooceIdList: [],
      popupTabCurrent: 0,
      show: false,
      searchCurrent: 0,
      opStatus: false,
      showList: [],
      Ar_List: [], //对战列表
      ranKingActive: 1,
      // TotalPrice: 0,
      game_odds_list: [],
      // 排行榜列表
      getRankingTopWTSList: {
        yesterday: [{ win: "0.00", user: { name: "暂无", avatar: "" } }],
        today: [{ win: "0.00", user: { name: "暂无", avatar: "" } }],
      },
      leftFun: "玩家对战",
      leftFunOne: "两人",
      robList: [],
      chooseRob: null,
      chooseBoxItem: null,
      status: 0,
      isme: false,
      activeName: "全部",
      headerName: "会员对战",
      headerTabList: [
        {
          icon: "icon-foo1.png",
          title: "幸运饰品",
          href: "/Lucky",
        },
        {
          icon: "icon-foo2.png",
          title: "虚拟对战",
          href: "/Battle",
        },
      ],
    };
  },
  created() {
    this.getRobList();
    this.GetMangHe_List();
    this.getRanking();
    setInterval(() => {
      setTimeout(() => {
        this.getRanking(this.ranKingActive);
      }, 0);
    }, 10000);
    // 创建房间
    this.$socket.Add("CreateGroup", this.Accept);
    // 对战开始
    // this.$socket.Add("startGroup", this.AddStartGroup);
  },
  // created() {

  //   // this.$socket.Add('endGroup', this.AddEndGroup)
  // },
  watch: {
    JieShuVsData() {
      this.GetArena_List(this.page, this.status);
    },
    AddInformation() {
      this.GetArena_List(this.page, this.status);
    },
    chooceList(Arr) {
      let ArrAy = [];
      for (let i = 0; i < Arr.length; i++) {
        ArrAy.push(Number(Arr[i].game_bean));
      }
      let Price = 0;
      for (let k = 0; k < ArrAy.length; k++) {
        Price = Price + ArrAy[k];
      }

      // this.TotalPrice = Price.toFixed(2);
    },
    current: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        // this.Ar_List = [];
        this.headerName = "会员对战";
        this.isme = false;
        this.status = 0;
        if (newVal == 1) {
          this.status = 2;
          this.headerName = "游戏历史";
        }
        if (newVal == 2) {
          this.GetTen_Arena_List();
          this.headerName = "十佳对战";
        } else {
          this.GetArena_List(1, this.status);
        }
      },
    },
    show: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          let scrollWidth =
            window.innerWidth - document.documentElement.offsetWidth;
          document.body.style.overflow = "hidden";
          document.body.style.paddingRight = scrollWidth + "px";
        } else {
          document.body.style.overflow = "unset";
          document.body.style.paddingRight = 0 + "px";
        }
      },
    },
  },
  methods: {
    ...mapMutations(["ChangeAudioStatus", "User"]),
    ...mapActions([
      // 'GetJieShuVsData',
      "Refresh",
      "GetKaiShiVsData",
    ]),
    changeCurrentOnClick(index = 0) {
      this.$emit("changeCurrent", index);
      this.activeName = "全部";
    },
    dropItemClick(item) {
      this.page_num = 1;
      this.activeName = item;
      if (item === "我的") {
        this.isme = true;
      } else {
        this.isme = false;
      }
      this.GetArena_List(1, this.status);
    },
    MouseWheelOnScroll(e) {
      let eventDelta = -e.wheelDelta || -e.deltaY * 40;

      let scrollDiv = this.$refs.scrollDiv;

      scrollDiv.scrollLeft = scrollDiv.scrollLeft + eventDelta / 2;
    },
    chooseRobOnClick(item) {
      if (this.chooseRob === item) {
        this.chooseRob = null;
      } else {
        this.chooseRob = item;
      }
    },
    getRobList() {
      getRobListApi().then((res) => {
        this.robList = res.data.data;
      });
    },
    leftFunOnclickOne(item) {
      this.leftFunOne = item;
    },
    leftFunOnclick(item) {
      if (item === this.BAR1) {
        this.chooseRob = null;
      }
      this.leftFun = item;
    },
    //接收房间信息(websocket)
    Accept(data) {
      console.log("接受111", data, this, this.current);
      if (this.current == 0) {
        this.Ar_List = [data, ...this.Ar_List];
        console.log(66666, this.Ar_List);
        // console.log(4444441, this.Ar_List);
        // this.GetArena_List(1, this.status);
      }
    },
    //对战开始
    // AddStartGroup(data) {
    //   this.GetKaiShiVsData(data);
    // },
    //对战分页
    handleCurrentChange(val) {
      this.GetArena_List(val.page, this.status);
    },
    // 获取排行榜
    async getRanking(type = 1) {
      this.ranKingActive = type;
      if (type == 1) {
        let king = await winRanking();
        this.getRankingTopWTSList = king.data.data;
      } else {
        let { data } = await winRanking();
        this.getRankingTopWTSList = data.data;
      }
    },
    //获取对战列表
    GetArena_List(page = 1, status = 0) {
      console.log(3333322222, this.current, this.isme, status);
      let API = Arena_List;
      if (this.current == 1 && this.isme) {
        API = My_Arena_List;
      }
      API(page, status, this.isme).then((res) => {
        this.per_page = res.data.data.per_page;
        this.PageNum = res.data.data.total;
        if (status === 0) {
          this.Ar_List = res.data.data.data.map((item) => {
            const parseItem = item;
            if (item.status === 2) {
              parseItem.status = 1;
            }
            return {
              ...parseItem,
            };
          });
        } else {
          this.Ar_List = res.data.data.data;
        }
        console.log(4444, this.Ar_List);
      });
    },
    // 获取十佳对战列表
    GetTen_Arena_List() {
      const params = {
        start_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"),
        end_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"),
        sort: 1,
      };
      Ten_Arena_List(params).then((res) => {
        // this.per_page = res.data.data.per_page;
        // this.PageNum = res.data.data.total;
        this.Ar_List = res.data.data;
      });
    },
    createRoomOnClick1: _.throttle(
      function () {
        // this.$socket.Start();
        const num = this.leftFunOne === this.BAR3 ? 2 : 3;
        this.$socket.Event(() => {
          let box = [];
          for (let i = 0; i < this.chooceIdList.length; i++) {
            box.push(this.chooceIdList[i]);
          }
          Arena_Create(num, box).then((res) => {
            console.log("创建对局接口成功");
            if (res.data.code === 200) {
              const id = res.data?.data?.id;
              this.$message({
                message: "创建成功",
                type: "success",
              });
              this.show = false;
              if (this.chooseRob && id && this.leftFunOne === this.BAR3) {
                this.joinRob(id);
              } else {
                this.$router.push({
                  path: "/vsbox",
                  query: {
                    id,
                    Code: 0,
                  },
                });
              }
              this.GetPersonalInfo();
              this.Refresh();
              this.GetArena_List(1, this.status);
              // location.reload()
            } else {
              this.$message({
                message: res?.data?.message || "系统异常，请刷新后重试",
                type: "error",
              });
            }
          });
        });
      },
      3000,
      { trailing: false }
    ),
    // 创建对战并选了机器人
    joinRob(id) {
      const params = {
        game_arena_id: id,
        bot_id: this.chooseRob.id,
      };
      joinRobApi(params).then((res) => {
        this.$router.push({
          path: "/vsbox",
          query: {
            id,
            Code: 0,
          },
        });
      });
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },
    //箱子减少
    chooceOnclickDown(item) {
      let reverceArr = this.chooceIdList.reverse();
      const index = reverceArr.indexOf(item.id);
      reverceArr.splice(index, 1);
      this.chooceIdList = reverceArr.reverse();
      // this.chooceIdList =
      // const idArray = this.chooceList.map(item => {
      //   return item.id
      // })
      // const index = idArray.lastIndexOf(item.id)
      // this.chooceList.splice(index, 1)
      // const arr = this.chooceList.reverse().splice(this.chooceList.indexOf(item), 1).reverse()
    },
    //箱子选择
    chooceOnclick(item) {
      if (this.chooceIdList.length < 10) {
        this.chooseBoxItem = item;
        // let key = "Select";
        // let value = true;
        // item[key] = value;

        // this.$refs.Active[index].style =
        //   "box-shadow: inset 0px 0px 7px 2px rgba(232, 90, 90, 0.73);";
        this.chooceList.push(item);
        this.chooceIdList.push(item.id);
      } else {
        this.$message({
          message: "最多选择10个盲盒!",
          type: "error",
        });
      }
    },
    boxCancel() {
      this.chooceList.forEach((_, index) => {
        this.$refs.Active[index].style = "";
      });
      this.chooceList = [];
    },
    // 弹窗菜单点击
    popupTabChange(i) {
      this.popupTabCurrent = i;
    },
    // 弹窗查看已选
    operateChange() {
      this.opStatus = !this.opStatus;
      if (this.opStatus) {
        this.showList = this.chooceList;
      }
    },
    // tabSearch(i, status) {
    //   this.tabStatusList = this.tabStatusList.map((item, index) => {
    //     let tempObj = {};
    //     tempObj = item;
    //     if (index == i) {
    //       tempObj.check = true;
    //     } else {
    //       tempObj.check = false;
    //     }
    //     return tempObj;
    //   });
    //   this.GetArena_List(1, this.tabStatusList[i].status);
    // },
    restIntegral(val) {
      if (val >= 1000) {
        return (val / 1000).toFixed(2) + "k";
      }
      return val;
    },
    scroll(type) {
      const sum = Math.abs(Math.ceil(this.hotboxlist.length / 5)) * -1;
      if (type == "pre") {
        this.boxListScroll += 1;
        return;
      }
      if (this.boxListScroll > sum + 1) {
        this.boxListScroll -= 1;
        return;
      }
      this.$message({
        message: "没有更多啦!",
        type: "warning",
      });
    },
    //打开箱子弹框
    OpenXZshow(item) {
      this.XZshow = true;
      this.TreasureName = item.name;
      this.Treasure_box_prize = item.contains;
      this.game_odds_list = item.game_odds_list;
    },
    //盲盒列表
    GetMangHe_List() {
      Arena_Box_List().then((res) => {
        this.hotboxlist = res.data.data;
        this.chooseBoxItem = this.hotboxlist[0];
      });
    },
    //创建房间相关
    //打开抽屉
    Getdrawer() {
      this.PeopleNum = "2";
      this.leftFunOne = this.BAR3;
      this.chooceList = [];
      this.chooceIdList = [];
      this.show = true;
    },
    searchChange(i, type) {
      this.searchCurrent = i;
    },
  },
  computed: {
    ...mapState(["JieShuVsData", "AddInformation"]),
    TotalPrice() {
      let total = 0;
      if (this.chooceIdList.length) {
        this.chooceIdList.forEach((item) => {
          total += this.hotboxlistById[item].game_bean * 1;
        });
      }
      return total.toFixed(2);
    },
    hotboxlistById() {
      let obj = {};
      this.hotboxlist.forEach((item) => {
        obj[item.id + ""] = item;
      });
      return obj;
    },
    chooceListNum() {
      const obj = {};
      this.chooceIdList.forEach((item) => {
        if (Object.keys(obj).includes(item + "")) {
          obj[item + ""] = obj[item + ""] * 1 + 1;
        } else {
          obj[item + ""] = 1;
        }
      });
      return obj;
    },
    renderList() {
      return this.getRankingTopWTSList[
        ["today", "yesterday"][this.searchCurrent]
      ];
    },
    No1() {
      return this.renderList[0] || { user: {} };
    },
    No2() {
      return this.renderList[1] || { user: {} };
    },
    No3() {
      return this.renderList[2] || { user: {} };
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-enter-active,
.popup-leave-active {
  transition: all 1s ease;
}

@keyframes ani {
  from {
    // transform: translateX(100%);
  }

  to {
    // transform: translateX(0);
  }
}

.popup-enter-to {
  animation: ani 0.5s ease-in-out forwards;
}
.view-member {
  padding: 0rem 0.18rem;
  .tab-box {
    width: 3.35rem;
    height: 0.34rem;
    background: red;
    margin: 0 auto;
    margin-bottom: 0.2rem;
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    background: url(../../../assets/images/new/header-bg.png) no-repeat;
    background-size: 100% 100%;
    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
    }
    .tab-item-active {
      background: url(../../../assets/images/new/header-active-bg.png) no-repeat;
      background-size: 120% 182%;
      background-position: -0.159rem -0.1rem;
    }
  }
}
.box-mod {
  width: 100%;
  display: flex;
  background: url(../../../assets/images/battle/title-bg3-new.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  margin-bottom: 0.12rem;
  .VSbox_list {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1.09rem;
      height: 0.31rem;
      cursor: pointer;
    }

    .pre {
      top: 0.5rem;
      transform: translateX(-50%) rotate(180deg);
    }

    .next {
      bottom: 0.5rem;
    }

    .scroll-wrap {
      width: 100%;
      height: 0.8rem;
      overflow: hidden;
      // height: 3.1rem;
      display: flex;
      align-items: center;
      padding: 0.12rem 0.12rem;
      .wrap001 {
        display: flex;
        overflow-x: auto;

        .item-box {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 0.56rem;
          height: 0.6rem;
          flex-shrink: 0;
          margin-right: 0.08rem;
          box-sizing: border-box;
          overflow: hidden;
          cursor: pointer;
          text-align: center;
          background: url(../../../assets/images/battle/item-bg3-new.png)
            no-repeat;
          background-size: 100% 100%;

          .vsbox_pic {
            width: 100%;
            max-height: 0.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;

            .img {
              width: 0.32rem;
              height: 100%;
              .img1 {
                width: 100%;
                // max-height: 0.25rem;
                // height: 100%;
              }
            }

            .img2 {
              position: absolute;
              width: 46%;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -60%);
              z-index: 10;
            }
          }

          .vsbox_data {
            font-size: 0.08rem;
            margin-top: -0.16rem;
            .p {
              color: #dea61f;
              margin-top: 0rem;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    .vslist_tit {
      width: 120px;
      text-align: center;
      padding: 0.1rem 0;

      p {
        font-size: 0.22rem;
        color: #fff;
        margin-bottom: 0.05rem;
      }

      span {
        font-size: 0.16rem;
      }
    }
  }

  .BoxList {
    width: 11.5rem;

    margin-top: 0.4rem;

    span {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.Navigation1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.18rem;
  p {
    width: 0.96rem;
    height: 0.4rem;
    text-align: center;
    line-height: 0.4rem;
    font-size: 0.14rem;
    border-radius: 0.04rem;
    background: #282828;
    cursor: pointer;
  }

  .Navigation-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.16rem 0.07rem;
    width: 0.28rem;
    height: 0.835rem;
    background: url(../../../assets/images/mobile/m-batter1-new.png) no-repeat;
    background-size: 100% 100%;
    position: fixed;
    left: 0;
    top: 1.71rem;
    z-index: 9;
    .right-text {
      font-size: 0.12rem;
      color: #fff;
    }
  }
  .Navigation-left2 {
    top: 2.71rem;
  }

  .Navigation-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.48rem;
    height: 0.42rem;
    // background: url(../../../assets/images/mobile/m-batter10-new.png) no-repeat;
    // background-size: 100% 100%;
    z-index: 99;
    font-size: 0.16rem;
    color: #ced6fb;
    position: fixed;
    bottom: 0.98rem;
    left: 50%;
    transform: translateX(-50%);
    // box-shadow: #ced6fb 0px 0px 0.04rem 0px;
    // background-color: rgba(0, 0, 0, 0);
    .filter-box {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #110056;
      filter: blur(0.16rem);
      z-index: -2;
    }
    .bg-box {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(../../../assets/images/mobile/m-batter10-new.png)
        no-repeat;
      background-size: 100% 100%;
      z-index: -1;
    }
    .img {
      width: 0.18rem;
      height: 0.18rem;
      flex-shrink: 0;
      margin-right: 0.03rem;
    }
  }
}
.sort-box-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sort-box {
  width: 3.35rem;
  height: 4.92rem;
  background: url(../../../assets/images/battle/title-bg3-new1.png) no-repeat;
  background-size: 100% 100%;
  padding: 0.38rem 0.18rem 0.18rem 0.18rem;
  box-sizing: border-box;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0.12rem;
  position: relative;
  .close-btn {
    width: 0.12rem;
    height: 0.12rem;
    position: absolute;
    right: 0.12rem;
    top: 0.12rem;
    background: url(../../../assets/images/battle/close-btn.png) no-repeat;
    background-size: 100% 100%;
  }
  .battle-search-box {
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.1rem;
    .search-item {
      width: 1.1rem;
      height: 0.21rem;
      line-height: 0.21rem;
      text-align: center;
      // background: #000000;
      // border-radius: 0.3rem;
      // box-shadow: inset 0px 0px 7px 3px #267b81;
      font-size: 0.12rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-left: 0.07rem;
      cursor: pointer;
      background: url(../../../assets/images/battle/search-bg1-new.png)
        no-repeat;
      background-size: 100% 100%;
    }

    .s-ac {
      // box-shadow: inset 0px 0px 7px 3px #570000;
      background: url(../../../assets/images/battle/search-bg-new.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
  }
  .sort-l {
    width: 2.44rem;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-wrap: wrap;
    margin-bottom: 0.12rem;

    .l-box {
      // transform: scale(0.7);
      width: 100%;
      // margin-bottom: 0.12rem;
      // height: 3.98rem;
      // background: url(../../../assets/images/battle/sort-bg2.png);
      // background-size: 100% 100%;
      position: relative;
      // overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      // padding: 0.35rem 0.15rem;
      .game-list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        box-sizing: border-box;
        align-content: flex-end;
        // padding: 0.24rem 0.49rem 0;
        // overflow: hidden;
        font-size: 0.18rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        height: 1rem;
        // padding: 0.35rem 0.15rem;

        .item-two,
        .item-one,
        .item-three {
          width: 0.7rem;
          flex-shrink: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          font-size: 0.1rem;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // max-width: 0.7rem;
          // flex-shrink: 0;
          .pic {
            background: url(../../../assets/images/battle/pic1-new.png)
              no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0.65rem;
            height: 0.66rem;
            margin-bottom: 0.06rem;
            padding: 0.12rem 0.11rem 0.05rem;
            img {
              width: 0.428rem;
              height: 0.428rem;
              background: wheat;
              border-radius: 50%;
              // margin-bottom: 0.03rem;
            }
          }
          .name {
            margin-bottom: 0.03rem;
            min-width: 0.4rem;
            text-align: center;
            max-width: 0.5rem;
          }

          // .name,
          // .integral {
          //   width: 1.2rem;
          //   font-size: 0.12rem;
          //   text-align: center;
          //   margin-bottom: 0.03rem;
          // }
        }
        .item-one {
          // margin-top: 0.16rem;
        }
        .item-two {
          // margin-top: 0.6rem;
          // transform: translate(0rem, 0.09rem);
          .pic {
            background: url(../../../assets/images/battle/pic2-new.png)
              no-repeat;
            background-size: 100% 100%;
            width: 0.62rem;
            height: 0.6rem;
            padding: 0.12rem 0.11rem 0.07rem;
            img {
              width: 0.393rem;
              height: 0.393rem;
              // transform: translate(0.002rem, -0.045rem);
            }
          }
        }
        .item-three {
          // margin-top: 0.4rem;
          // transform: translate(0rem, 0.14rem);
          .pic {
            background: url(../../../assets/images/battle/pic3-new.png)
              no-repeat;
            background-size: 100% 100%;
            width: 0.6rem;
            height: 0.57rem;
            padding: 0.09rem 0.1rem 0.09rem;
            img {
              width: 0.393rem;
              height: 0.393rem;
              // transform: translate(0.002rem, -0.045rem);
            }
          }
        }
      }

      .sort-bt {
        // position: absolute;
        // bottom: 0.43rem;
        // display: block;
        width: 2.14rem;
        // left: 50%;
        // transform: translateX(-50%);
      }
    }
  }

  .sort-r {
    // flex: 1;
    box-sizing: border-box;
    width: 100%;
    // padding: 0.53rem 0.52rem 0 0;

    .sort-list {
      .sort-item {
        width: 3rem;
        height: 0.36rem;
        background: url(../../../assets/images/battle/sort-item-bg.png)
          no-repeat;
        background-size: 100% 100%;
        margin-bottom: 0.06rem;
        padding: 0.07rem 0.18rem;
        display: flex;
        align-items: center;
        .num {
          font-family: Bevan, Bevan;
          font-size: 0.14rem;
          color: #544d8c;
          margin-right: 0.25rem;
          width: 0.1rem;
          flex-shrink: 0;
        }
        .header-img {
          margin-right: 0.2rem;
          width: 0.22rem;
          height: 0.22rem;
          border-radius: 50%;
          overflow: hidden;
          .img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 0.12rem;
          color: #7a71dc;
          width: 1.17rem;
          flex-shrink: 0;
          margin-right: 0rem;
        }
        .price {
          font-size: 0.1rem;
          color: #efa900;
        }
      }
      // display: flex;
      // justify-content: space-between;
      // width: 100%;
      // margin-top: 0.19rem;

      // .list-l,
      // .list-r {
      //   width: 49%;

      //   .sort-user-item {
      //     margin-top: 0.07rem;
      //     width: 1.51rem;
      //     height: 0.38rem;
      //     background: url(../../../assets/images/battle/item-bg4.png) no-repeat;
      //     background-size: 100% 100%;
      //     display: flex;
      //     box-sizing: border-box;
      //     // padding: 0 0 0 0.56rem;

      //     .pic-box {
      //       display: flex;
      //       align-items: center;
      //       font-size: 0.12rem;
      //       font-family: Microsoft YaHei;
      //       font-weight: 400;
      //       color: #ffffff;

      //       .sort-num-img {
      //         width: 0.2rem;
      //         height: 0.2rem;
      //         padding: 0.02rem;
      //         box-sizing: border-box;
      //       }

      //       span {
      //         width: 0.24rem;
      //         height: 0.24rem;
      //         line-height: 0.24rem;
      //         text-align: center;
      //       }

      //       .sort-pic {
      //         width: 0.24rem;
      //         height: 0.24rem;
      //         background: #ffffff;
      //         border-radius: 50%;
      //         // margin-left: 0.07rem;
      //       }
      //     }

      //     .info-box {
      //       display: flex;
      //       flex-direction: column;
      //       justify-content: center;
      //       margin-left: 0.09rem;
      //       font-size: 0.14rem;
      //       font-family: Microsoft YaHei;
      //       font-weight: 400;
      //       color: #ffffff;
      //       .name,
      //       .integral {
      //         font-size: 0.12rem;
      //         margin-top: 0.04rem;
      //         display: flex;
      //       }
      //     }
      //   }
      // }
    }
  }
}
.header-box2 {
  width: 100%;
  height: 0.44rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.16rem;
  margin-bottom: 0.12rem;
  position: relative;
  .back-box2 {
    position: absolute;
    width: 0.25rem;
    height: 0.14rem;
    left: 0rem;
    top: 0.13rem;
    z-index: 3;
    background: url(../../../assets/images/new/back-icon.png) no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.header-box3 {
  display: flex;
  margin-bottom: 0.12rem;
  padding-left: 0.04rem;
  .item {
    font-size: 0.12rem;
    color: #636363;
    margin-right: 0.32rem;
  }
  .active-item {
    color: #c5bbed;
  }
}
.content-list {
  .fight-box-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .fight-box-list-item {
      width: 1.65rem;
      height: 0.94rem;
      flex-shrink: 0;
      margin-bottom: 0.12rem;
    }
  }
}

.battle-show-wrapper1 {
  // display: flex;
  // justify-content: flex-end;
  // align-items: flex-end;
  // background: red;
  width: 100%;
  height: 100vh;
  // height: 3.51rem;
  // width: 5.99rem;
  // position: fixed;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);

  .popup-show-member-2 {
    width: 3.35rem;
    height: 5.95rem;
    position: fixed;
    left: 50%;
    top: 54%;
    transform: translate(-50%, -50%);
    // background: url(../../../assets/images/mobile/m-batter4.png) no-repeat;
    // background-size: 100% 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0.12rem;
    // border: 0.02rem solid #0d1636;
    // padding: 0.62rem 0.22rem;
    // margin-right: -0.5rem;
    // padding: 0.56rem 0.56rem 0.36rem 0.56rem;
    // padding-bottom: 0;
    box-sizing: border-box;
    position: relative;

    .close-btn-left {
      width: 0.12rem;
      height: 0.12rem;
      background: url(../../../assets/images/mobile/m-batter5-new.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: -0.19rem;
      right: 0rem;
    }

    .popup-show-member-box {
      width: 100%;
      height: 100%;
      // background: #eee;
      display: flex;
      align-items: center;

      .box-main-left {
        width: 1rem;
        height: 100%;
        background: #000;
        flex-shrink: 0;

        .left-top-content {
          width: 100%;
          height: 1.7rem;
          background: url(../../../assets/images/battle/popup-bg1.png) no-repeat;
          background-size: 100% 100%;
          // padding-top: 0.26rem;
          // padding-left: 0.46rem;
          padding: 0.26rem 0.56rem;
          box-sizing: border-box;

          .top-title {
            text-align: center;
            margin-bottom: 0.34rem;
            font-size: 0.2rem;
          }

          .odd-b {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .odd-b-item {
              width: 40%;
              display: flex;
              align-items: center;
              margin-bottom: 0.2rem;

              .item-color {
                width: 0.2rem;
                height: 0.2rem;
                background: red;
                margin-right: 0.16rem;
              }

              .item-title {
              }
            }
          }
        }

        .left-center-list {
          width: 100%;
          height: calc(100% - 1.7rem);
          overflow-y: auto;

          .list-item {
            padding: 0.24rem 0.2rem;
            box-sizing: border-box;
            background: url(../../../assets/images/battle/popup-bg2.png)
              no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: flex-start;
            font-size: 0.16rem;

            .list-item-left {
              width: 0.7rem;
              height: 0.7rem;
              display: flex;
              align-items: center;
              justify-content: center;
              // background: url('../../../assets/images/battle/popup-bg-lv4.png') no-repeat;
              // background-size: 100% 100%;
              // background: #666;
              margin-right: 0.2rem;
              flex-shrink: 0;
            }

            @for $i from 1 through 4 {
              .list-item-left#{ $i } {
                background: url("../../../assets/images/battle/popup-bg-lv#{$i}.png")
                  no-repeat;
                background-size: 100% 100%;
              }
            }

            .list-item-center {
              flex-grow: 1;
              line-height: 0.26rem;
              width: 1.3rem;
              margin-right: 0.1rem;
            }

            .list-item-right {
              width: 0.56rem;
              line-height: 0.26rem;
              color: #ecd105;
              font-family: "PingFang SC-Medium";
            }
          }
        }
      }

      .box-main-right {
        // background: url(../../../assets/images/battle/popup-right.png) no-repeat;
        // background-size: 100% 100%;

        box-sizing: border-box;
        height: 100%;
        .right-top {
          padding: 0rem 0.3rem 0 0.18rem;
          width: 100%;
          display: flex;
          align-items: center;

          .right-top-left {
            font-size: 0.22rem;
            margin-right: 0.21rem;
          }

          .right-top-right {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 0.54rem;

            .box-item {
              width: 0.54rem;
              height: 0.54rem;
              margin-right: 0.2rem;
              // background: red;
              position: relative;

              .item-close {
                position: absolute;
                width: 0.12rem;
                height: 0.12rem;
                background: url(../../../assets/images/battle/popup-bg7.png)
                  no-repeat;
                background-size: 100% 100%;
                right: -0.06rem;
                top: 0rem;
              }
            }
          }

          .right-top-right-box {
            margin-left: auto;
            display: flex;
            align-items: center;

            .num-box {
              margin-right: 0.23rem;
              font-size: 0.16rem;
            }

            .clear-box {
              width: 1.32rem;
              height: 0.43rem;
              font-size: 0.16rem;
              color: #6772b0;
              // background: url(../../../assets/images/battle/popup-bg16.png)
              //   no-repeat;
              // background-size: 100% 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: underline;
            }
          }
        }

        .right-center-box-list {
          // background: url(../../../assets/images/battle/popup-right.png)
          //   no-repeat;
          // background-size: 100% 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          // height: 40vh;
          overflow-y: auto;
          height: 4.4rem;
          padding: 0.12rem 0;
          border: 0.02rem solid #0d1636;
          // padding: 0 0.05rem;
          .box-list-item {
            width: 0.96rem;
            height: 0.9rem;
            flex-shrink: 0;
            margin: 0 0.06rem;
            background: url(../../../assets/images/mobile/m-batter6-new.png)
              no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            margin-bottom: 0.12rem;
            padding-top: 0.1rem;
            position: relative;

            .left-box {
              width: 100%;
              height: 0.48rem;
              // background: pink;
              flex-shrink: 0;
              // position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              .img {
                height: 100%;
              }
              .num-box {
                width: 0.14rem;
                height: 0.14rem;
                position: absolute;
                left: 0.05rem;
                top: 0.05rem;
                font-size: 0.1rem;
                background: url(../../../assets/images/mobile/m-batter-num-bg.png)
                  no-repeat;
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .down {
                width: 0.19rem;
                height: 0.13rem;
                // background: red;
                position: absolute;
                right: 0.01rem;
                bottom: 0.12rem;
                font-size: 0.12rem;
                background: url(../../../assets/images/mobile/m-batter-down.png)
                  no-repeat;
                background-size: 100% 100%;
              }
              // margin-right: 0.24rem;
            }

            .right-content {
              font-size: 0.12rem;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              align-content: space-between;
              justify-content: center;

              .content-title {
                width: 100%;
                line-height: 0.16rem;
                text-align: center;
                font-size: 0.1rem;
              }

              .content-price {
                color: #dda52c;
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.1rem;
              }

              .item1-btm-box {
                // position: absolute;
                width: 100%;
                height: 0.36rem;
                // background: url(../../../assets/images/battle/popup-bg15.png) no-repeat;
                // background-size: 100% 100%;
                bottom: 0.3rem;
                // left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 0 0.3rem;

                .price-box {
                  font-size: 0.18rem;
                  color: #2e3780;
                  flex-grow: 1;
                }

                .right-num {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 0.7rem;

                  .down {
                    width: 0.16rem;
                    height: 0.16rem;
                    background: url(../../../assets/images/battle/popup-bg13.png)
                      no-repeat;
                    background-size: 100% 100%;
                  }

                  .num {
                    font-size: 0.18rem;
                    color: #fff;
                  }

                  .up {
                    background: url(../../../assets/images/battle/popup-bg14.png)
                      no-repeat;
                    background-size: 100% 100%;
                    width: 0.16rem;
                    height: 0.16rem;
                  }
                }
              }
            }
          }

          .box-list-item1 {
            background: url(../../../assets/images/mobile/m-batter7-new.png)
              no-repeat;
            background-size: 100% 100%;
            position: relative;
          }
        }

        .btm-box {
          position: absolute;
          background: url(../../../assets/images/mobile/popup-bg4-new.png)
            no-repeat;
          background-size: 100% 100%;
          // background: #0060a8;
          width: 100%;
          height: 1.24rem;
          left: 0px;
          bottom: 0.5rem;
          display: flex;
          align-items: center;
          // padding: 0 0.69rem;
          margin: 0 0rem;
          font-size: 0.12rem;
          flex-wrap: wrap;
          padding: 0.14rem;
          .btm-left {
            margin-right: 0.23rem;
            color: #8b95c8;
            .left1-checkbox {
              width: 0.12rem;
              height: 0.12rem;
              background: url(../../../assets/images/battle/popup-bg6-new.png)
                no-repeat;
              background-size: 100% 100%;
              margin-right: 0.04rem;
            }

            .left1-checkbox-checked {
              background: url(../../../assets/images/battle/popup-bg5-new.png)
                no-repeat;
              background-size: 100% 100%;
            }

            .btm-left1 {
              flex-shrink: 0;
              margin: 0.1rem 0;
              width: 0.7rem;
            }

            .btm-left2 {
            }
          }

          .btm-center {
            flex-grow: 1;
            // margin-top: -0.33rem;
            .join-list {
              .join-list-item {
                width: 0.54rem;
                height: 0.2rem;
                background: url(../../../assets/images/battle/popup-bg10-new.png)
                  no-repeat;
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.12rem;
              }

              .join-list-item-checked {
                background: url(../../../assets/images/battle/popup-bg9-new.png)
                  no-repeat;
                background-size: 100% 100%;
              }
            }

            .rob-list {
              display: flex;
              align-items: center;

              .rob-list-item {
                margin-right: 0.05rem;
                flex-shrink: 0;
                width: 0.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                font-size: 0.12rem;
                .rob-img {
                  width: 0.3rem;
                  height: 0.3rem;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-bottom: 0.04rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                    width: 94%;
                    height: 94%;
                    border-radius: 50%;
                  }
                }

                .rob-name {
                  font-size: 0.1rem;
                  // color: #658DB5;
                  color: #fff;
                  white-space: nowrap;
                }
              }

              .rob-list-item-active {
                // border: 2px solid red;

                .rob-img {
                  background: url("../../../assets/images/about/user/achead-new.png")
                    no-repeat;
                  background-size: 100% 100%;
                }

                .rob-name {
                  color: #dda52c;
                }
              }
            }
          }

          .btm-right {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .all-num {
              color: #8b95c8;
            }
            .clear-box {
              width: 0.66rem;
              height: 0.19rem;
              color: #6772b0;
              // background: url(../../../assets/images/mobile/m-batter8.png)
              //   no-repeat;
              // background-size: 100% 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: underline;
            }
            .right-btn {
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.12rem;
              width: 1.5rem;
              height: 0.43rem;
              background: url(../../../assets/images/battle/popup-bg8-new.png)
                no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}

// .battle-show-wrapper {
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   min-width: 100vw;
//   min-height: 100vh;

//   .popup-show-member {
//     width: 88%;
//     height: 73vh;
//     background: url(../../../assets/images/battle/popup-bg.png) no-repeat;
//     background-size: 100% 100%;
//     box-sizing: border-box;
//     padding: 0.36rem 0rem 0.37rem 0.35rem;
//     display: flex;

//     .item {
//       width: 2.3rem;
//       display: flex;
//       font-size: 0.16rem;
//       font-family: Adobe Heiti Std;
//       font-weight: normal;
//       color: #ffffff;
//       height: 0.695rem;
//       box-sizing: border-box;
//       background: url(../../../assets/images/battle/popup-item-bg.png) no-repeat;
//       background-size: 100% 100%;
//       padding: 0 0.21rem 0 0.09rem;
//       align-items: center;

//       .tips {
//         width: 0.4rem;
//         white-space: nowrap;
//       }

//       img {
//         // width: 0.92rem;
//         // height: 0.52rem;
//         margin: 0 0.21rem;
//       }

//       .twice {
//         width: 0.4rem;
//       }
//     }

//     .left-box {
//       width: 2.3rem;
//       height: 100%;
//       position: relative;

//       .icon-back {
//         position: absolute;
//         top: 5.5rem;
//         left: -0.2rem;
//         width: 0.18rem;
//         height: 0.61rem;
//         cursor: pointer;
//       }

//       .title {
//         height: 0.68rem;
//         line-height: 0.68rem;
//         text-align: center;
//         font-size: 0.25rem;
//         font-family: Adobe Heiti Std;
//         font-weight: normal;
//         color: #ffffff;
//       }
//     }

//     .right-box {
//       position: relative;
//       flex: 1;

//       .close-icon {
//         position: absolute;
//         top: -0.5rem;
//         right: 0.09rem;
//         cursor: pointer;
//       }

//       .top-list {
//         display: flex;
//         width: 13.6rem;
//         overflow-x: auto;
//         overflow-y: hidden;
//         height: 0.68rem;

//         .item {
//           img {
//             width: 0.92rem;
//             height: 0.52rem;
//           }
//         }

//         &::-webkit-scrollbar {
//           height: 4px;
//         }

//         &::-webkit-scrollbar-track {
//           background-color: #003366;
//         }

//         &::-webkit-scrollbar-thumb {
//           background-color: #49c8e1;
//           border-radius: 3px;
//         }
//       }

//       .content {
//         box-sizing: border-box;
//         padding: 0.44rem 0.26rem 0;

//         .active {
//           display: flex;
//           justify-content: space-between;

//           .text {
//             font-size: 0.25rem;
//             font-family: Microsoft YaHei;
//             font-weight: 400;
//             color: #ffffff;
//           }

//           .active-num {
//             font-size: 0.18rem;
//             font-family: Adobe Heiti Std;
//             font-weight: normal;
//             color: #ffffff;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: center;

//             .btn {
//               cursor: pointer;
//               margin-top: 0.06rem;
//               width: 0.53rem;
//               height: 0.3rem;
//               line-height: 0.3rem;
//               text-align: center;
//               background: #0066cc;
//               opacity: 0.23;
//               border-radius: 0.05rem;
//               font-size: 0.16rem;
//               font-family: Microsoft YaHei;
//               font-weight: 400;
//               color: #cccccc;
//             }
//           }
//         }

//         .popup-tab {
//           display: flex;
//           align-items: center;
//           margin-top: 0.19rem;

//           .left {
//             flex: 1;
//             display: flex;

//             .p-t-item {
//               width: 1.28rem;
//               height: 0.36rem;
//               border-radius: 0.2rem 0rem 0.31rem 0rem;
//               text-align: center;
//               font-size: 0.18rem;
//               font-family: Microsoft YaHei;
//               font-weight: 400;
//               color: #ffffff;
//               line-height: 0.36rem;
//               cursor: pointer;
//               box-shadow: inset 0px 0px 7px 2px rgba(85, 232, 255, 0.63);
//               background: #006699;
//               margin-right: 0.1rem;
//             }

//             .ac {
//               background: #780101;
//               box-shadow: inset 0px 0px 7px 2px rgba(232, 90, 90, 0.73);
//             }
//           }

//           .right {
//             width: fit-content;
//             font-size: 0.16rem;
//             font-family: Microsoft YaHei;
//             font-weight: 400;
//             color: #cccccc;
//             display: flex;

//             span {
//               &:first-child {
//                 cursor: pointer;
//                 margin-right: 0.09rem;
//                 display: flex;
//                 align-items: center;
//               }
//             }

//             img {
//               width: 0.15rem;
//               height: 0.15rem;
//               margin-right: 0.09rem;
//             }
//           }
//         }

//         .show-list {
//           display: flex;
//           flex-wrap: wrap;
//           align-content: start;
//           max-height: 4.8rem;
//           overflow-y: auto;

//           .show-item {
//             width: 3.27rem;
//             // height: 1.38rem;
//             background: #09143d;
//             box-shadow: inset 0px 0px 7px 2px rgba(85, 232, 255, 0.63);
//             border-radius: 0.05rem;
//             margin-right: 0.15rem;
//             margin-top: 0.18rem;
//             cursor: pointer;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-size: 0.24rem;
//             font-family: Microsoft YaHei;
//             font-weight: 400;
//             color: #ffffff;

//             .show-right-box {
//               width: 1.3rem;
//               margin-left: 0.26rem;
//               height: 0.7rem;
//               display: flex;
//               flex-direction: column;
//               justify-content: space-between;
//             }

//             &:nth-child(4n) {
//               margin-right: 0;
//             }
//           }
//         }
//       }

//       .show-bottom {
//         margin-top: 0.5rem;
//         box-sizing: border-box;
//         padding: 0 0.41rem;

//         .show-bottom-top {
//           display: flex;
//           align-items: center;
//           height: 0.56rem;
//           font-size: 0.18rem;
//           font-family: Microsoft YaHei;
//           font-weight: 400;
//           color: #cccccc;

//           img {
//             width: 0.15rem;
//             height: 0.15rem;
//           }

//           .auto {
//             margin-left: 0.4rem;
//           }

//           .show-btn {
//             width: 3.48rem;
//             height: 0.56rem;
//             cursor: pointer;
//             background: #6600cc;
//             border-radius: 0.41rem 0rem 0.41rem 0rem;
//             font-size: 0.25rem;
//             text-align: center;
//             line-height: 0.56rem;
//             // box-shadow: inset 0px 0px 7px 1px #eee;
//             // margin-left: 0.62rem;
//           }

//           .show-btn-active {
//             box-shadow: inset 0px 0px 14px 0px #fff;
//           }

//           .btn-three {
//             background: #af5b07;
//             margin-left: 0.42rem;
//           }
//         }

//         .show-b-b {
//           display: flex;
//           align-items: center;
//           font-size: 0.2rem;
//           font-family: Microsoft YaHei;
//           font-weight: 400;
//           color: #cccccc;
//           margin-top: 0.48rem;
//           justify-content: space-between;

//           .create-num {
//             display: flex;
//             align-items: center;

//             input {
//               outline: none;
//               width: 0.87rem;
//               height: 0.36rem;
//               background: #cccccc;
//               border: 0.02px solid rgba(153, 102, 255, 0.63);
//               border-radius: 0.03rem;
//               color: #333;
//               margin-left: 0.45rem;
//             }
//           }

//           .add-btn {
//             width: 2.71rem;
//             height: 0.56rem;
//             line-height: 0.56rem;
//             text-align: center;
//             background: #cc6600;
//             border: 0.01px solid rgba(255, 153, 51, 0.63);
//             border-radius: 0.51rem 0rem 0.51rem 0rem;
//             box-shadow: inset 0px 0px 7px 1px #eee;
//             cursor: pointer;
//           }
//         }
//       }
//     }
//   }
// }

.popup-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-box {
    width: 13.3rem;
    height: 9.02rem;
    position: relative;
    background: #000;

    .popup-box-main2 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url(../../../assets/images/battle/bgdetail.png) no-repeat;
      background-size: 100% 100%;
      padding: 1.46rem 0;
      box-sizing: border-box;

      .popup-box-title {
        position: absolute;
        width: 2.52rem;
        height: 0.7rem;
        left: 1.23rem;
        top: 0.62rem;
        background: url(../../../assets/images/battle/bg-title.png) no-repeat;
        background-size: 100% 100%;
      }

      .popup-box-odd {
        padding: 0 1rem;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.1rem;

        .item {
          display: flex;
          align-items: center;
          margin: 0.1rem;

          @for $i from 1 through 5 {
            .open-lv#{ $i } {
              background: lv-color($i);
            }
          }

          .item-point {
            width: 0.3rem;
            height: 0.3rem;
            margin-right: 0.1rem;
          }

          .item-odd {
          }
        }
      }

      .popup-box-close {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-top: 0.4rem;
        position: absolute;
        bottom: 0.5rem;
        width: 100%;

        .close-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.22rem;
          height: 0.69rem;
          background: url(../../../assets/images/battle/btn-bg3.png) no-repeat;
          background-size: 100% 100%;
          font-size: 0.28rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
